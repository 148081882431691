<template>
  <b-card-code title="Modifier Groupe">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du Groupe </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="jourFerier"
      ref="simpleRules"
    >
      <b-form>
        <b-row>

          <b-col md="6">
            <label for="example-datepicker">Date</label>
            <b-form-datepicker
              v-model="jourFerier.dated"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Label"
              label-for="Label"
            >
              <validation-provider
                #default="{ errors }"
                name="Label"
                rules="required"
              >
                <b-form-input
                  v-model="jourFerier.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Label"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editGroup"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  //   BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker, // vSelect,
    // BFormCheckbox,

  },
  data() {
    return {
      jourFerier: {},
      required,
    }
  },
  created() {
    this.getjourFerier()
  },
  methods: {

    async getjourFerier() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/holidays/detail/${id}`)
      this.jourFerier = response.data
      this.load = 'false'
    },
    async editGroup() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/holidays/update/${id}/`,
                {
                  Dated: this.jourFerier.dated,
                  label: this.jourFerier.label,
                },
              )
            this.$router.push('/JourFerie')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Jour Ferie Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
